.header {
  padding: 20px 32px;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.015);
  > p {
    font-weight: 600;
    font-size: 16px;
    color: #464255;
    margin: 0;
  }
}

.trush {
  cursor: pointer;
}

.body {
  display: flex;
  gap: 20px;
  &_item {
    background: #ffffff;
    border-radius: 6px;
    width: 100%;
    height: 161px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;
    font-weight: 500;
    font-size: 32px;
    line-height: 16px;
    color: #00a389;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.015);
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 20px;
  &_left {
    padding: 64px;
    min-width: 500px;
    background: #ffffff;
    border-radius: 6px;
    min-height: 424px;
    margin-right: 20px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.015);
  }
  &_right {
    width: 60%;
    background: #ffffff;
    border-radius: 6px;
    min-height: 424px;
    padding-bottom: 10px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.015);
    &_header {
      padding: 16.8px 22.4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > h1 {
        font-weight: 600;
        font-size: 16px;
        color: #464255;
      }
      > button {
        box-sizing: border-box;
        border-radius: 50px;
        width: 209px;
        height: 38px;
      }
    }
  }
}
